






















import {Component, Prop, Vue} from "vue-property-decorator";
import {Post} from "@/services/Post";
import {BField} from 'buefy/dist/esm/field'
import {BInput} from 'buefy/dist/esm/input'
import {BSwitch} from "buefy/src/components/switch"
import {BButton} from "buefy/dist/cjs/button";
import {PostService} from "@/services/PostService";

@Component({
  components: {
    'b-field': BField,
    'b-input': BInput,
    'b-button': BButton,
    "b-switch": BSwitch,
  }
})
export default class PostEdit extends Vue {
  @Prop() private post: Post
  text: string = this.post.text
  author: string = this.post.author
  hidden: boolean = this.post.hidden
  published: boolean = this.post.published

  async save() {
    const post = this.post
    post.published = this.published
    post.hidden = this.hidden
    post.text = this.text
    post.author = this.author
    await PostService.Instance.save(this.post.id, this.post)
  }
}
