
















import {Component, Vue} from "vue-property-decorator";
import {BButton} from 'buefy/dist/esm/button'
import {BModal} from 'buefy/dist/esm/modal'
import {BField} from 'buefy/dist/esm/field'
import {BInput} from 'buefy/dist/esm/input'
import {AuthService} from "@/services/AuthService";
import {RequestHelper} from "@/services/RequestHelper";

@Component({
  components: {'b-modal': BModal, 'b-button': BButton, 'b-field': BField, 'b-input': BInput}
})
export default class Login extends Vue {
  login = ""
  password = ""
  isActive = true

  async created() {
    const tokenValid = await AuthService.isTokenValid()
    if (tokenValid) {
      this.exit()
    }
  }

  exit() {
    this.isActive = false
    this.$emit('exit')
  }

  async getToken() {
    const token = await AuthService.getToken(this.login, this.password)
    if (token) {
      AuthService.saveToken(token)
      this.exit()
    }
  }
}
